import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#nadcha"> &gt; Radca(NÁDCHA)</a>
            <a
              href="/radca/nadcha/alergicka_nadcha_spoznajte_ju_a_predchadzajte_jej/"
              className="sel"
            >
              {" "}
              &gt; Alergická nádcha – spoznajte ju a predchádzajte jej
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_1206612040.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Alergická nádcha – spoznajte ju a predchádzajte jej</h1>
              <div className="wysiwyg">
                <p className="p1">
                  <span className="s1">
                    <b>
                      Na jar kvitnú stromy, kríky a iné rastliny. Je to čarovné
                      obdobie, kedy si môžeme oddýchnuť na čerstvom vzduchu,
                      usporiadať piknik na lúke alebo sa vybrať na prechádzku do
                      lesa. Žiaľ, pre alergikov je to veľmi ťažké obdobie.
                      Zápalom nosovej sliznice vyvolaným peľmi tráv, stromov a
                      burín trpí veľmi veľa ľudí.{" "}
                    </b>
                  </span>
                </p>
                <p className="p1">&nbsp;</p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Nádcha, kýchanie a škrabanie v hrdle sú časté príznaky,
                    ktoré si môžeme pomýliť s prechladnutím. Hoci prichádzajú
                    sezónne a každý rok, veľa ľudí, u ktorých sa{" "}
                    <a href="/slovnik_pojmov/#alergia" target="_blank">
                      alergia
                    </a>
                    &nbsp;prejaví prvýkrát, si neuvedomí, že príčinou ich
                    ťažkostí s dýchaním, slzenia a zápalu nosa sú vdychované{" "}
                    <a href="/slovnik_pojmov/#alergen" target="_blank">
                      alergény
                    </a>
                    .{" "}
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Máj je skutočnou explóziou peľu. V tomto čase kvitnú stromy
                    (najmä breza) a začína sa šíriť peľ tráv (najmä palina,
                    kostrava lúčna, reznačka laločnatá) – to všetko sú rastliny,
                    na ktoré sú alergici najčastejšie citliví. Príznaky, ktoré
                    vyvolávajú (napr. senná nádcha, pnutie v okolí nosa, pálenie
                    spojoviek a slzenie, ťažkosti so spánkom, svrbenie v nose,
                    bolesti hlavy, zahlienenie), komplikujú vykonávanie bežných
                    každodenných aktivít, ako napríklad: čítanie, používanie
                    počítača alebo športovanie. Okrem toho spôsobujú aj
                    oslabenie a podráždenie. K peľom, ktoré dráždia nosovú
                    sliznicu, patrí napríklad peľ liesky, jelše, žihľavy,
                    skorocelu a raže.{" "}
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Senná nádcha je obranná reakcia tela na{" "}
                    <a href="/slovnik_pojmov/#antigen" target="_blank">
                      antigény
                    </a>
                    , ktoré sa nachádzajú v peľoch rastlín, stromov, kríkov a
                    tráv. Bežné príznaky prvej alergie sa objavia už v detstve
                    alebo počas dospievania (u detí, dospievajúcich a dospelých
                    do dvadsať rokov). Alergický zápal nosovej sliznice vzniká
                    pri kontakte s látkou, ktorú organizmus chybne identifikuje
                    ako hrozbu, v dôsledku čoho začne produkovať protilátky.
                    Každý ďalší kontakt s takou látkou vyvoláva vylúčenie
                    histamínu a silné ťažkosti pozorovateľné hneď po kontakte s{" "}
                    <a href="/slovnik_pojmov/#alergen" target="_blank">
                      alergénmi
                    </a>
                    ), napr. vodnatý výtok z nosa, slzenie.{" "}
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    alt="Prípravky OLYNTH® sú tiež riešením pri sennej nádche"
                    src="/web/kcfinder/uploads/images/shutterstock_122248285.jpg"
                    style={{
                      float: "left",
                      margin: "5px 10px 10px 0px",
                      border: "2px solid rgb(255, 255, 255)",
                      height: "320px",
                      width: "372px"
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span className="s1">
                    Alergii ťažko predchádzať, pokiaľ je človek v kontakte s
                    alergénmi. Preto by sme mali vedieť, ako si s ňou poradiť.
                    Príznaky sennej nádchy možno zmierniť niekoľkými spôsobmi.
                    Alergici najčastejšie užívajú antihistaminiká, ktoré im
                    pomáhajú zvládnuť výtok z nosa, reflexné kýchanie, svrbenie
                    a slzenie očí a svrbenie a začervenanie kože, nemajú však
                    veľký účinok na upchatie nosa. Pri užívaní antihistaminík sa
                    preto oplatí používať aj lieky, ktoré uvoľňujú opuch nosovej
                    sliznice, ako sú napríklad nosové roztokové aerodisperzie{" "}
                    <a href="/lieky/olynth_01/" target="_blank">
                      OLYNTH<sup>®</sup> 0,1 %
                    </a>{" "}
                    alebo{" "}
                    <a href="/lieky/olynth_ha_01/">
                      OLYNTH<sup>®</sup> HA 0,1 %
                    </a>
                     a OLYNTH® PLUS 1 mg/50 mg/ml. Tieto lieky sa však môžu používať len obmedzený čas, preto
                    je veľmi dôležité riadiť sa pokynmi v písomnej informácii
                    pre používateľa takéhoto lieku. Na jar, keď sa začína
                    uvoľňovať peľ rastlín, sa treba chrániť pred{" "}
                    <a href="/slovnik_pojmov/#alergen" target="_blank">
                      alergénmi
                    </a>
                    . Nezabúdajte ani na časté umývanie tváre, dodržiavanie
                    čistoty v domácnosti a na pracovisku, prípadne na nosenie
                    ochranného rúška.{" "}
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p className="p1">&nbsp;</p>
                <p className="p1">
                  <span
                    style={{
                      "font-size": "11px",
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    Literatúra::
                  </span>
                </p>
                <ol className="ol1">
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span className="s1">
                        Jakimowicz-Klein Barbara,{" "}
                        <em>
                          Alergia u dzieci i dorosłych. Profilaktyka i skuteczne
                          metody leczenia
                        </em>
                        , Wydawnictwo Astrum, 2015.{" "}
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span className="s1">
                        Wrotek Katarzyna, <em>Alergia</em>, Septem, 2006.
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span className="s1">
                        Słowińska Anna,{" "}
                        <em>Alergie układu oddechowego – katar sienny</em>, [v:]
                        „Medycyna Rodzinna”, 2003, č. 2, s. 77-84.
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span className="s1">
                        Rapiejko Piotr,{" "}
                        <em>
                          Alergiczny nieżyt nosa – 42 pytania i odpowiedzi
                        </em>
                        , Warszawa, 2014.
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span className="s1">
                        Emeryk Andrzej,{" "}
                        <em>
                          Leki przeciwhistaminowe w chorobach alergicznych – co
                          jest ważne dla lekarza i pacjenta?
                        </em>
                        , [v:] „Alergologia”, 2009, č. 1.
                      </span>
                    </span>
                  </li>
                  <li
                    className="li1"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      <span className="s1">
                        A. Samoliński, <em>Przewlekłe nieżyty nosa</em>, [w:]
                        „Alergia”, 2014, nr 3, s. 49-55.
                      </span>
                    </span>
                  </li>
                </ol>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s5">
                      <a href="http://alergia.org.pl/lek/index.php?option=com_content&task=view&id=453&Itemid=79">
                        http://alergia.org.pl/lek/index.php?option=com_content&amp;task=view&amp;id=453&amp;Itemid=79
                      </a>{" "}
                      –{" "}
                    </span>
                    <span className="s6">źródło do danych liczbowych</span>
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a
                  href="/radca/nadcha/ako_si_spravne_cistit_nos/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg)" }} />
                  <span className="title">
                    Ako si správne čistiť nos pri nádche?
                  </span>
                </a>
                <a href="/radca/nadcha/spoznajte_typy_nadchy/" className="box">
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg)" }} />
                  <span className="title">Spoznajte typy nádchy</span>
                </a>
                <a
                  href="/radca/nadcha/zabojujte_s_nadchou_rychlo/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_152527238.jpg)" }} />
                  <span className="title">Zabojujte s nádchou rýchlo</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}

      </div>
    );
  }
}

export default Page;
